@import "../../index";
.wrapper {
  .content-box.projects-box {
    .fp-tableCell {
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .fp-slidesContainer {
    overflow: hidden;
  }

  // Slider Controls
  .fp-controlArrow,
  .fp-controlArrow.fp-prev,
  .fp-controlArrow.fp-next
  {
    top: 57%;
    width: 2.28rem;
    height: 2.28rem;
    border-radius: 68px;
    background-color: rgba(255, 255, 255, 0.6);
    background-size: auto;
    background-repeat: no-repeat;
    border-width: 0;
    font-size: 0.914rem;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    color: #393a37;
  }

  .fp-controlArrow.fp-prev {
    left: 3.3vw;
    background-image: url(/assets/img/projects/arrow-back.png);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .fp-controlArrow.fp-next {
    right: 3.3vw;
    background-image: url(/assets/img/projects/arrow.png);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .fp-controlArrow.fp-prev:before {
    //content: '\f060';
    //font-family: "Font Awesome 5 Free", sans-serif;
    position: absolute;
    top: 0.458rem;
    left: 0.742rem;
  }

  .fp-controlArrow.fp-next:before {
    //content: '\f061';
    //font-family: "Font Awesome 5 Free", sans-serif;
    position: absolute;
    top: 0.458rem;
    left: 0.742rem;
  }

  .fp-slidesNav.fp-bottom {
    z-index: -1;
  }

  #fp-nav ul li a span, .fp-slidesNav ul li a span {
    height: 0.15rem;
    width: 1rem;
    border-radius: 0;
    display: none;
  }

  .fp-slidesNav ul li a.active span,
  #fp-nav ul li a.active span,
  #fp-nav ul li:hover a.active span,
  .fp-slidesNav ul li a.active span,
  .fp-slidesNav ul li:hover a.active span {
    height: 0.4rem;
    width: 1rem;
    margin: -.2rem 0 0 -.1rem;
    border-radius: 0;
  }

  #fp-nav ul li, .fp-slidesNav ul li {
    width: 1rem;
    margin: 0.5rem;
  }

  #fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
    height: .15rem;
    width: 1rem;
    margin: -0.1rem 0 0 -.1rem;
    border-radius: 0;
  }

  .navbar-collapse {
    margin-left: -23px;
  }


  .slide {
    transition: 3s;
    opacity: 0;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .fp-tableCell {
      justify-content: space-between;
      @extend .container;
    }

    .project-info {
      @extend .col-lg-4;
      @extend .col-md-5;
      @extend .col-sm-6;
      padding-right: 0;
      width: 100%;
      margin-top: 4.2vh;

      .cont {
        background-color: rgba(0, 0, 0, 0.8);
        //padding: 1.6rem 1.71rem;
        padding: 1.6rem 0 1.6rem 1.71rem;

        .box .text-line {
          @extend .header-bold;
          font-family: 'Gilroy', sans-serif;
          text-transform: uppercase;
          text-decoration: none;
          margin-top: 0 !important;
          line-height: 1.45rem;
          color: white;
        }

        .line-br {
          width: 30px;
          height: 1px;
          font-size: 1px;
          background-color: #b3b3b3;
          border-left: 30px solid #b3b3b3;
        }

        .box .text-small {
          @extend .text-medium;
          font-family: Gilroy, sans-serif;
          margin-top: 0.77rem;
          line-height: 1.37rem;
          color: #cccccc;

          sup {
            color: #cccccc;
          }

          .tip {
            font-size: 0.91rem;
            color: #cccccc;
            font-family: "Gilroy", sans-serif;
            font-weight: 500;
            font-style: italic;
          }
        }
      }
    }

    .shoutout-block {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0;
      //padding: 0.7rem 0.2rem 0.5rem 4.7rem;
      //background: rgba(255, 255, 255, .5);
      .box {
        @extend .container;
        display: flex;
        padding: 0;
      }

      .all-cont {
        @extend .col-lg-4;
        @extend .col-md-5;
        @extend .col-sm-6;
        padding: 0 0 0 15px;
        height: 4.4rem;
        position: relative;

        .bg-bottom-line-my {
          background: rgba(255, 255, 255, .7);
          z-index: 0;
          position: absolute;
          height: 100%;
          width: 100vw;
          top: 0;
          right: -5px;
        }

        .bg-bottom-line-my:after {
          content: '';
          position: absolute;
          top: 0;
          right: -100%;
          height: 100%;
          width: 100%;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.36) 0, rgba(0, 0, 0, 0) 100%);

        }
      }

      .text-high-slide, .text-ital-slide {
        position: relative;
        z-index: 1;
        text-align: left;
        color: #262626;
      }

      .text-high-slide {
        @extend .header-bold;
        padding-top: 0.9rem;
        text-transform: uppercase;
        line-height: 1.5rem;
        font-family: Gilroy, sans-serif;
      }

      .text-ital-slide {
        @extend .text-medium-small;
        font-family: Gilroy, sans-serif;
      }
    }

    &.projects-multistory-hotel {
      background-image: url("/assets/img/projects/multistore-hotel.jpg");
      background-position: top;
    }

    &.projects-bungalow-resort {
      background-image: url("/assets/img/projects/bungalow-resort.jpg");
    }

    &.projects-homestay-rentals {
      background-image: url("/assets/img/projects/homestay-rentals.jpg");
    }

    &.projects-tropical-resorts {
      background-image: url("/assets/img/projects/tropical-resort.jpg");
      background-size: cover;
      background-position: inherit;
      //transform: scale(-1, 1);
      //.fp-tableCell{
      //  transform: scale(-1, 1);
      //}
    }
  }

  .slide.active {
    transition: 0.3s;
    opacity: 1;
  }


  .content-box {
    width: 100%;
    height: 100vh;
    color: #323231;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .fp-slides {
      position: absolute;
      top: 0;
      height: 100vh;
      z-index: 0;
      width: 100vw;
    }

    .fp-tableCell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .group {
      color: white;

      .box {
        position: relative;
      }

      .box-t {
        max-width: 1000px;
      }

      .box-btn {
        min-height: 50px;
        display: flex;
        justify-content: center;

        .bg-spot {
          filter: blur(19px);
        }
      }

      .box-texts {
        display: flex;
        justify-content: flex-end;
      }

      .box-block {
        position: absolute;
        bottom: 5vh;
        right: 5vw;
        color: white;
      }
    }

    .group-right {
      div {
        max-width: 500px;
      }
    }

    .group-left {
      margin-left: 0;
      padding-left: 0;

      div {
        max-width: 500px;
      }
    }


    .group-t {
      width: 80%;
      height: 80%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
    }
  }

  .price-content {
    .fp-tableCell {
      justify-content: space-around;
      align-items: flex-start;
      //padding-left: 10vw;
    }
  }

  .alight-between {
    .fp-tableCell {
      justify-content: space-between;
    }
  }

  .alight-end {
    .fp-tableCell {
      justify-content: flex-end;
      align-items: flex-start;
      //padding-left: 10vw;
    }
  }

  .box-absolute {
    padding: 0 15px;
    position: absolute;
    right: 4vw;
    bottom: 4vh;
    min-height: 50px;
    display: flex;
    justify-content: center;

    .bg-spot {
      filter: blur(19px);
    }
  }

  .slider-btn {
    @extend .container;
    position: relative;
    z-index: 99999;
    margin-bottom: 2.8vh;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 0;

    .btn {
      @extend .button-medium;
      cursor: pointer;
      max-width: 198.75px;
      color: #fff;
      font-family: "Gotham Pro", sans-serif;
      text-transform: uppercase;
      width: 11.9rem;
      border-radius: 0;
      height: 3rem;
      border: 1px solid #fff;
    }
  }

  // time-line
  .time-line {
    display: none;
    position: absolute;
    height: 1px;
    width: 100%;
    background: white;
    top: -3vh;

    .step {
      width: 0;
      background: #0a2360;
      height: 2px;
    }
  }

  @keyframes tick-line {
    from {
      width: 50%;
    }
    to {
      width: 100%;
    }
  }
}
.slide.active{
  .fp-tableCell{
    .group:nth-child(1){
      z-index: 999;
      animation-delay: .2s;
      animation-duration: 1s;
      animation-name: slideRightReturn;
      animation-fill-mode: both;
    }
    .all-cont{
      z-index: 999;
      animation-delay: .2s;
      animation-duration: 1s;
      animation-name: slideLeftReturn;
      animation-fill-mode: both;
    }
  }
}
@include media-breakpoint-down(md) {
  .wrapper .content-box.projects-box .fp-tableCell {
    padding-top: 66px;
    .slider-btn{
      display: none;
    }
  }
  .wrapper .content-box.projects-box .fp-tableCell .group  {
    align-items: flex-start!important;
  }
  .wrapper .slide .project-info, .wrapper .slide .shoutout-block .all-cont{
    max-width: 70%;
    min-width: 55%;
    .cont{
      width: 100%;
    }
  }
}

@include media-breakpoint-down(xs) {
  .wrapper .content-box.projects-box .fp-tableCell {
    .group .cont  >.box{
      display: none;
    }
  }
  .wrapper .slide .project-info .cont {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    .box .text-line{
      padding-right: 20px;
    }
  }
  .wrapper .slide .project-info, .wrapper .slide .shoutout-block .all-cont{
    max-width: 100%;
    min-width: 100%;
    .cont{
      width: 100%;
    }
  }
}
@supports (-ms-ime-align:auto) {
    .wrapper .slide .shoutout-block .box{
      overflow: hidden!important;
    }
}
