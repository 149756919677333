@import "../../index";

.upload-wrapper{
  position: relative;
  img{
    height: .8rem;
    width: auto;
  }
  .btn{
    display: flex;
    align-items: center;
    padding: 0;
  }
  .upload-text{
    @extend .text-medium-small;
    color: white;
    text-transform: none;
    font-family: Gilroy,sans-serif;
    padding: 0 1.14rem;
  }
  .filepond--wrapper{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.fa-trash-alt{
  color: white;
  position: absolute;
  right: 1.25rem;
  cursor: pointer;
}
