@import "../../../index";

.popup-wrapper.technology-popup {
  .container {
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;

    .p-slide, .slick-list, .slick-track {
      height: 100% !important;
    }

    .slick-slider {
      height: 100%;
      max-height: 38.8rem;
    }

    .elements {
      width: 100%;
      height: 100%;
      max-height: 38.8rem;
      position: relative;
    }

    .p-slide {
      background-color: white;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .photos {
          height: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 3.14rem;

          .photo {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .photo:nth-child(1) {
            margin-right: .57rem;
            background-image: url("/assets/img/popup/technology/technology-slide-1.png");
          }

          .photo:nth-child(2) {
            margin-left: .57rem;
            background-image: url("/assets/img/popup/technology/technology-slide-2.png");
          }
        }

        .head-text {
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
          padding-bottom: 1.25rem;
        }

        .table {
          border: 1px solid #f2f2f2;
          margin-top: .228rem;

          thead {
            background-color: #0a2360;

            tr {
              height: 4.68rem;
            }

            th {
              background-color: #0a2360;
              padding: 0;
              vertical-align: middle;
              width: 9.3rem;
              text-align: center;

              div {
                @extend .text-bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Gilroy", sans-serif;
                color: white;
                width: 9.3rem;
                line-height: 1.2rem;
                text-align: center;
              }

              &.residio-tech {
                padding: 3px;
                div {
                  padding: 0;
                  background-color: white;
                  color: #0a2360;
                  height: 4rem;

                  img {
                    height: 1.88rem;
                    width: auto;
                  }
                }
              }

            }

            th:nth-child(1) {
              width: 100%;
            }
          }

          tbody {
            tr {
              height: 4rem;
              vertical-align: middle;

              th {
                @extend .text-bold-small;
                padding-left: 1.5rem;
                vertical-align: middle;
                color: #262626;
                font-family: "Gilroy", sans-serif;
              }

              td {
                vertical-align: middle;
                padding-left: 0;
                padding-right: 0;

                img {
                  margin: 0 auto;
                  height: 1.257rem;
                  width: auto;
                }
              }

              td:nth-last-child(1) {
                background-color: #fafafa;
              }
            }
          }
        }

        .text-blue {
          @extend .header-medium;
          margin-top: 2rem;
          padding-left: 2rem;
          color: #262626;
          position: relative;
        }

        .text-blue:before {
          content: '';
          height: 100%;
          width: 2px;
          background-color: #0a2360;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.technology-popup-active {
  display: flex;
}
