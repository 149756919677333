@import "../../index";

.technology-box{
  background: white;
}
.technology-group {
  @extend .stretch;
  height: 100%;
  .container{
    //margin-top: 8.7vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    > div{
      height: 73vh;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .build-boxes{
      display: flex;
      flex-direction: column;
    }
  }
  .block {
    height: 100%;

    .btn {
      padding: 0;
      width: 8rem;
      margin: 0;
      height: 2rem;
    }
  }


  .block:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bg-box{
      border-bottom: 1px solid #d9d9d9;
      background-color: #f7f7f7;
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 15.3vh;
      margin-right: 2.4vw;
    }
    .bg-box:after, .bg-box:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    .bg-box:nth-child(4):after, .bg-box:nth-child(4):before {
      left: calc(100% + 13px);
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .bg-box:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #f7f7f7;
      border-width: 13px;
      margin-left: -13px;
    }
    .bg-box:nth-child(4):after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #f7f7f7;
      border-width: 14px;
      margin-left: -14px;
      margin-top: -14px;
    }
    .bg-box:before {
      border-top-color: #d9d9d9;
      border-width: 14px;
      margin-left: -14px;
    }
    .bg-box:nth-child(4):before {
      border-left-color: #d9d9d9;
      margin-top: -15px;
      border-width: 15px;
    }
    .bg-box:nth-child(1){
      img {
        height: 100%;
        width: auto;
      }
    }
    .bg-box:last-child{
      border-bottom: none;
      border-right: 1px solid #d9d9d9;
    }
    img {
      height: auto;
      width: 50%;
    }
  }

  .block:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 3.5rem;
    img {
      width: 100%;
      height: auto;
    }

    .text-box {
      @extend .header-medium;
      padding-left: 1.8rem;
      width: 100%;
      position: relative;
      font-family: Gilroy, sans-serif;
      max-width: 517px;
      color: #262626;
    }

    .text-box:before {
      content: '';
      position: absolute;
      border: 1px solid #0a2360;
      top: 0;
      left: 0;
      height: 100%;
      background: #323231;
    }
    .img-box{
      margin-top: 9vh;
      height: 100%;
      width: 100%;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      img{
        height: auto;
        width: 100%;
        padding: 0 36px;
      }
    }
  }

  .block:nth-child(3) {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .box:nth-child(1){
      margin-bottom: 4.4vh;
    }
    .title {
      @extend .header-middle;
      color: #262626;
      font-family: Gilroy, sans-serif;
      padding-bottom: 1.3rem;
    }

    .text-i {
      @extend .text-medium;
      color: #808080;
      font-family: Gilroy, sans-serif;
      //font-size: 0.95rem;
      //font-weight: 500;
      padding-bottom: 0.5rem;
      display: flex;
      i {
        text-align: center;
        margin-right: 0.7rem;
        font-size: 1.2rem;
        color: grey;
        width: 100%;
        max-width: 25px;
      }
    }
  }
  .page-btn{
    margin-bottom: 10px;
    margin-left: auto;
  }
}

@include media-breakpoint-down(md) {
  .wrapper {
    .technology-group{
      width: 100%;
      padding-top: 66px;
      .container {
        height:auto;
        display: flex;
        flex-direction: column;
        position: relative;
        > div{
          //min-height: 100%;
          /* display: flex; */
          justify-content: center;
          //padding-right: 34px;
          width: 100%;
          //position: absolute;
          top: 0;
          height: fit-content;
          flex-direction: column;
        }
        .block {
          padding: 0;
          width: 100%;
          max-width: 100%;
          height: fit-content;
          .img-box {
            margin-top: 1rem;
          }
        }

        .block:nth-child(3) .box:nth-child(1) {
          margin-bottom: 1rem;
        }

        .block:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .wrapper {
    .technology-group .container{
      height: auto;
      padding: 0 17px!important;
      margin-top: 1rem;
      margin-bottom: 1rem;
      //height: 90%;
      //overflow-y: auto;
      //overflow-x: hidden;
      position: relative;
      //flex-direction: column-reverse;
      > div{
        height: auto;
      }
      .block{
        //max-height: 2000px;
        .img-box{
          height: auto;
        }
      }
    }
    .group{
      display: flex;
      align-items: center;
    }
    .block:nth-child(2) .img-box img{
      padding: 0;
    }
  }
}
