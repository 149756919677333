@import "../../index";
.solutions-box{
  background: url("/assets/img/franchising/bg.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
}
.content-box.solutions-box {
  background-color: #F7F7F7;

  .fp-tableCell {
    justify-content: space-around;
    align-items: flex-start;
  }
}

.solution-block {
  @extend .stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100vw;
  height: 100%;
  //background: url("/assets/img/franchising/bg.png");
  //background-position: left;
  //background-size: contain;
  //background-repeat: no-repeat;
  //background-color: #f7f7f7;

  padding-top: 80px;
  .container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 42rem;
    margin-top: 7vh;
    margin-bottom: 7vh;
    padding-right: 17px;
    padding-left: 17px;
    padding-top: 0px;
    //padding-right: 32px;
  }
  .cards-s {
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: space-around;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;

    .card-s {
      max-height: 30.9rem;
      height: 60vh;
      margin: 0;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.18);
      background: white;
      width: 100%;
      display: flex;
      flex-direction: column;

      .image {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
      }

      .title {
        @extend .header-middle;
        font-family: 'Gilroy',sans-serif;
        padding: 1.2rem 1.2rem 0.1rem;
        color: #262626;
      }

      .big-text-c {
        @extend .text-medium-small;
        font-family: Gilroy, sans-serif;
        overflow: hidden;
        padding: 0 1.2rem;
        max-height: 2.5rem;
        height: 100%;
        line-height: 1.05rem;
      }

      .group-block {
        margin-bottom: 1rem;
        padding: 0.5rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .list {
          .line-s {
            @extend .text-medium-small;
            font-family: "Gilroy",sans-serif;
            white-space: normal;
            //padding: .15rem .6rem;
            //max-height: 16px;
            padding: 0  0.6rem 0 1.1rem;
            position: relative;
          }

          .line-s:before {
            position: absolute;
            content: '';
            //top: .6rem;
            top: .4rem;
            left: 0;
            width: 5px;
            border-radius: 20px;
            height: 5px;
            background: #2f2e71;
          }
        }

        img {
          //width: 8rem;
          height: 5rem;
        }
      }
    }
  }

  .line-text-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    .text-l-s {
      @extend .header-medium;
      padding: 0.3rem 1.7rem;
      line-height: 1.4rem;
      position: relative;
      font-family: Gilroy, sans-serif;
    }

    .text-l-s:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border: 1px solid  #0a2360;
      background: #0a2360;
    }

    .btn {
      padding: 0;
      line-height: 1.9rem;
      width: 8rem;
      margin: 0 0;
      height: 2rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .wrapper {
    .solution-block{
      .container{
        margin-top: 0;
        margin-bottom: 0;
        height: auto;
        .cards-s{
          height: auto;
          //grid-template-columns: 1fr;
          //grid-row-gap: 20px;
          display: flex;
          flex-direction: column;
          .card-s{
            //margin-top: 1rem;
            overflow: hidden;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
