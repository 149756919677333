// BOOTSTRAP SASS
$grid-breakpoints: (
        xs: 0,
        sm: 550px,
        md: 740px,
        lg: 980px,
        xl: 1367px
);
$container-max-widths: (
        xs: 320px,
        sm: 550px,
        md: 560px,
        lg: 960px,
        xl: 1280px
);


@import '~bootstrap/scss/bootstrap.scss';
// --END BOOTSTRAP SASS

// QUERIES
.header-bold {
    font-size: 1.02rem;
    font-weight: 800;
}

.header-middle {
    font-size: 1.02rem;
    font-weight: 700;
}

.header-medium {
    font-size: 1.02rem;
    font-weight: 500;
}

.text-bold {
    font-size: .91rem;
    font-weight: 800;
}

.text-middle{
    font-size: .91rem;
    font-weight: 700;
}

.text-semibold {
    font-size: .91rem;
    font-weight: 600;
}

.text-medium {
    font-size: .91rem;
    font-weight: 500;
}

.text-bold-small {
    font-weight: 800;
    font-size: .8rem;
}

.text-semibold-small {
    font-weight: 600;
    font-size: .8rem;
}

.text-medium-small {
    font-weight: 500;
    font-size: .8rem;
}

.text-regular-small {
    font-weight: 400;
    font-size: .8rem;
}

.button-medium {
    font-size: .8rem;
    font-weight: 700;
}

.menu-text {
    font-size: .8rem;
    font-weight: 700;
}

.footer-text {
    font-size: .685rem;
    font-weight: 500;
}
// --END QUERIES

// DEFAULT STYLE
html {
    font-size: 17.5px;
}

body, * {
    color: #343434;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
}

// animation
.magictime {
    animation-duration: 1s;
    z-index: 999;
}

.magictime {
    animation-delay: 200ms;
}

.animate {
    transform: translateX(-100%);
}

// end animation

.page-btn {
    cursor: pointer;
    width: 100%;
    min-height: 2.74rem;
    max-width: 11.355rem;
    height: 2.9rem;
    border: 1px solid #0a2360;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
        min-width: 100%;
        min-height: 100%;
        color: #0a2360;
        font-family: "Gotham Pro", sans-serif;
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 22px;
        text-transform: uppercase;
        margin: 0;
    }
}

//.fp-scroller {
////    height: 100%;
////}

.container {
    //padding-right: 32px;
}

.vision-active {
    nav {
        z-index: 0;
    }
}

.input-my {
    @extend .text-medium-small;
    height: 100%;
    max-height: 2.857rem;
    min-height: 2.857rem;
    border: none;
    border-radius: 0;
    padding: 0 1.2rem;
}

.lay-textarea textarea{
    @extend .text-medium-small;
}

// My Custom Elements Style (select, input...)
.input-my::placeholder {
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
}


// --End My Elements Style

// tick-animation (time here)
.time-line {
    .start-clock-animation {
        animation: tick-line 6s linear 1;
    }
}

//  ANIMATION STYLES
.magictime {
    animation-duration: 1s;
}
#fullpage{
    min-height: 100vh;
    position: fixed!important;
}
.slide-box{
    z-index: 10;
}
.contact-box{
    z-index: 8;
}
.mobile-revert{
    .wrapper .navbar .container{
        max-width: 97%;
    }
    .wrapper .navbar .navbar-toggler.c-nav{
        display: flex;
    }
    .page-btn, .slider-btn{
        display: none;
    }
    .vision-block{
        .vision-collage{
            pointer-events: none;
        }
    }
    .technology-box .fp-scroller{
        height: fit-content;
        .container > div{
            margin: 2rem;
            min-height: 73vh;
            height: fit-content;
        }
    }

    .vision-block .container {
        .vision-collage:nth-child(2){
            margin-bottom: 0;
        }
    }
    .service-block .container, .vision-block .container, .franchising-block .container{
        margin: 2rem auto;
    }
    .solution-block{
        .container{
            margin: 2rem auto;
            height: fit-content;
            .line-text-block{
                padding-top: 30px;
            }
        }
    }
}
// POPUP
//@import "../elements/popup/popup.scss";
//@import "../elements/popup/contact-form-statuses/contact-form.scss";

// MEDIA
@import "./media.scss";
