@import "../../index";
.services-box{
  align-items: center;
  background-image: url("/assets/img/services/service-min.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.service-block{
  @extend .stretch;
  height: 100%;
  width: 100%;
  display: flex;
  //align-items: center;
  //background-image: url("/assets/img/services/service-min.png");
  //background-size: cover;
  //background-position: center;
  //background-repeat: no-repeat;
  .container{
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .s-bg-block{
    height: fit-content;
    width: 100%;
    //max-width: 35rem;
    //max-height: 38rem;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 3rem 3.6rem 1.6rem 3rem;
    color: white;
    .line-text{
      @extend .header-medium;
      margin-bottom: 2.9rem;
      display: flex;
      color: #ffffff;
      font-family: Gilroy, sans-serif;
      position: relative;
      .line{
        margin-right: 1.8rem;
        position: relative;
        height: auto;
        border: 1px solid  white;
        background: white;
      }
    }
    .s-i-div{
      display: flex;
      margin-bottom: 0.8rem;
      img{
        width: 2.57rem;
        height: 2.57rem;
        margin-right: 1.6rem;
      }
      .s-i-texts{
        margin-bottom: 0.7rem;
        .title{
          @extend .header-middle;
          color: white;
          font-family: Gilroy, sans-serif;
          margin-bottom: 0.38rem;
        }
        ul {
          padding-left: 0.8rem;
          li{
            @extend .text-medium-small;
            color: #b3b3b3;
            font-family: Gilroy, sans-serif;
            line-height: 1.37rem;
          }
        }
      }
      .s-i-texts:last-child{
        margin-bottom: 0;
      }
    }
  }
  .page-btn{
    color: white;
    border-color: white;
    border-width: 0;
    .btn{
      border-radius: 0;
      color: white;
      border-color: white;
    }
  }
}
@include media-breakpoint-down(sm) {
  .service-block .container{
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    width: calc(100% - 17px);
    height: auto;
    .s-bg-block{
      //margin: 0 17px;
      //position: absolute;
      top: 0;
      left: 0;
      width: auto;
      min-width: 0;
      height: fit-content;
    }
  }
  .service-block .s-bg-block .s-i-div img{
    height: 100%;
  }
  .service-block .s-bg-block .line-text .line{
    display: none;
  }
}
