@import "../../../index";

.popup-wrapper.solution-popup{
  .container{
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    .p-slide,.slick-list,.slick-track{
      height: 100%!important;
    }
    .slick-slider{
      height: 100%;
      max-height: 46.51rem;
    }
    .elements{
      width: 100%;
      height: 100%;
      max-height: 46.51rem;
      position: relative;
    }
    .p-slide{
      background-color: white;
      .content{
        height: 100%;
        display: flex;
        flex-direction: column;
        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.55rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
          padding-bottom: 1.25rem;
        }
        .photo{
          height: 100%;
          width: 100%;
          background-image: url("/assets/img/popup/solution/solution-slider-photo.jpg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          margin-bottom: 2.48rem;
        }
        .items{
          height: 37.02rem;
          display: grid;
          grid-column-gap: 1.14rem;
          width: 100%;
          grid-template-columns: repeat(3, 1fr);
          .item{
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            height: 100%;
            .item-head{
              @extend .header-middle;
              height: 5.02rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              justify-content: center;
              background-color: #0a2360;
              color: white;
              font-family: 'Gilroy', sans-serif;
              br {
                line-height: 0;
              }
              small{
                @extend .text-medium;
                font-family: 'Gilroy', sans-serif;
                color: white;
                display: contents;
              }

            }
            .item-body{
              height: 32rem;
              img{
                width: 100%;
                height: auto;
                padding: 0  .8rem;
              }
              .block{
                display: flex;
                align-items: center;
                height: 50%;
                width: 100%;
              }
              .block:nth-child(1){
                border-bottom: 1px solid #e6e6e6;
              }
            }
          }
        }
      }
    }
  }
}
.solution-popup-active{
  display: flex;
}
