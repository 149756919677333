
@media screen and (max-width: 1550px) , screen and (max-height: 800px) {
  .wrapper .service-block .s-bg-block{
    padding: 4vh 3.5vw;
  }
  .wrapper .franchising-block .container{
    .elements .element .box{
      height: 100%;
      max-height: 23.48rem;
      min-height: 0;
    }
  }
  .wrapper .vision-block .vision-collage{
    max-height: 260px;
  }
}
@include media-breakpoint-down(xs) {
  .wrapper .navbar-collapse.show {
    .navbar-nav{
      padding-bottom: 130px;
      position: absolute;
      top: 0;
      right: 16%;
      width: 100vw;
      height: calc(100vh - 60px);
      display: flex;
      align-items: center;
      justify-content: center;
      .c-nav{
        height: auto;
      }
      .bg-help{
        width: 200%;
      }
      .c-nav{
        display: block;
      }
    }
    .navbar-nav:before{
      content: '';
      top: 0;
      left: -100%;
      position: absolute;
      background: rgba(255,255,255,.9);
      height: 100%;
      width:100%;
    }
  }
  //.wrapper .content-box .group .cont > .box{
  //  display: none!important;
  //}
  .wrapper .content-box .group .box.collapsing{
    right: 0;
  }
  .wrapper .content-box .group .box .fas{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }
  .wrapper .content-box .group .box .fas.fa-chevron-down,
  .wrapper .content-box .group .collapsed.box .fas.fa-chevron-up{
    display: none;
  }
  .wrapper .content-box .group .box .fas.fa-chevron-up,
  .wrapper .content-box .group .collapsed.box .fas.fa-chevron-down{
    display: block;
  }
  .wrapper .content-box .group .drop .box > div{
    padding-right: 10px;
  }
  .wrapper .slide .project-info .cont{
    padding: 1.08rem 0.74rem;
  }

  // slide controls
  .wrapper .fp-slidesNav.fp-bottom{
    z-index: 5;
    bottom: 2vh;
    ul li {
      a{
        background: rgba(255, 255, 255, 0.44);
        border: 1px solid rgba(255, 255, 255, 0);
        border-radius: 10px;
        height: 9px;
        width: 9px;
      }
      .active{
        background: white;
        height: 15px;
        width: 15px;
        top: -3px;
      }
    }
  }
}
@include media-breakpoint-up(xs) {
  html {
    font-size: 13.5px;
  }
  .wrapper nav {
    height: 60px;
    .container{
      padding: 0 15px;
    }
  }
  .stretch {
    padding-top: 66px;
  }
  nav .container > .c-nav{
    display: none;
  }
  .fp-controlArrow{
    display: none;
  }
  .wrapper .slide .shoutout-block{
    display: none;
  }
  .bg-help{
    display: block;
  }
  .technology-group .container .block:nth-child(1){
    padding: 0;
    .bg-box{
      margin-right: 0;
    }
  }
  //.wrapper .technology-group .block.hidden-xm-down, .wrapper .technology-group .block:nth-child(2){
  //  display: none;
  //}
  .wrapper .solution-block {
    .cards-s{
      grid-template-columns: 1fr;
      //.card-s:nth-child(2), .card-s:nth-child(3){
      //  display: none;
      //}
    }
    .line-text-block{
      .text-l-s{
        display: none;
      }
    }
  }

}
@include media-breakpoint-down(sm) {
  .wrapper nav .logo img{
    height: 2.75rem;
  }
  //.wrapper .technology-group .block .text-i i{
  //  display: none;
  //}
  //.wrapper .franchising-block .container{
  //  .f-text{
  //    display: none;
  //  }
  //  .elements{
  //    grid-template-columns: 1fr!important;
  //    .element:nth-child(2){
  //      display: none;
  //    }
  //  }
  //}
  .service-block .s-bg-block{
    padding: 10px!important;
    .line-text{
      margin-bottom: 1rem;
    }
    //.s-i-div:nth-child(4),
    //.s-i-div:nth-child(5){
    //  display: none;
    //}
  }
  .wrapper .vision-block{
    .vision-collage:nth-child(1){
      .img-block{
        margin-right: 0;
      }
      //.img-block:nth-child(2){
      //  display: none;
      //}
    }
    .vision-collage:nth-child(2){
      .img-block{
        margin-right: 0;
      }
      //.img-block:nth-child(1){
      //  display: none;
      //}
    }
  }
  .wrapper .navbar-collapse.show .navbar-nav{
    //border-right: 0.3px solid rgba(255, 255, 255, 0.9);
    //border-left:  0.3px solid rgba(255, 255, 255, 0.9);
  }
  .wrapper .navbar-collapse.show .navbar-nav .c-nav{
    margin-top: 30px;
  }
}
@include media-breakpoint-up(sm) {
  html {
    font-size: 13.5px;
  }
  .wrapper nav {
    height: 100px;
  }
  .stretch {
    padding-top: 66px;
  }
  .fp-controlArrow{
    display: block;
  }
  .wrapper .slide .shoutout-block{
    display: block;
  }
  nav .container > .c-nav{
    display: block;
  }
  .wrapper .technology-group .block:nth-child(2){
    display: flex;
  }
  .drop{
    display: none!important;
  }
}
@include media-breakpoint-up(md) {
  html {
    font-size: 12.3px;
  }
  nav .navbar-collapse .c-nav{
    display: none;
  }

  .stretch {
    padding-top: 65px;
  }

  .wrapper .slide .fp-tableCell {
    padding-top: 65px;
  }
  .wrapper .solution-block {
    padding-top: 65px;

    .cards-s{
      grid-template-columns: 1fr 1fr;
      //.card-s:nth-child(3){
      //  display: none;
      //}
      //.card-s:nth-child(2){
      //  display: flex;
      //}
    }
    .line-text-block{
      .text-l-s{
        display: none;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .popup-wrapper.franchasing-popup .container .p-slide .content .block-list .item .elm .text-items p{
    padding-bottom: .1rem;
  }
  .wrapper .contact-box .contact-block .container form{
    padding: 2rem 0;
  }

  .popup-wrapper {
    .container {
      //margin-top: calc(65px + 4.2vh);
    }
  }

  .wrapper .contact-box .contact-block .container {
    .input-lay,
    .group-inputs-white,
    .group-radio-white,
    .dynamic-block,
    .controls, .group-labels{
      margin-bottom: 0.78rem;
    }

    .dynamic-block {
      max-height: 15rem;
      min-height: 15rem;

      .inquiry-block textarea {
        //max-height: 13.3rem;
      }
    }

    .lay-textarea{
      //max-height: 10rem;

      textarea {
        //max-height: 8.5rem;
      }
    }
  }
  .header-middle{
    font-size: 1.1rem;
    font-weight: 700;
  }
  .header-bold{
    font-size: 1.3rem;
    font-weight: 800;
  }
  .header-medium{
    font-size: 1.1rem;
    font-weight: 500;
  }

  //** change variables
  .text-bold,
  .text-semibold,
  .text-medium,
  .text-regular{
    font-size: .95rem;
  }

  .text-bold-small,
  .text-semibold-small,
  .text-medium-small,
  .text-regular-small
  {
    font-size: .95rem;
  }
  //** --end change variables

  .wrapper nav {
    max-height: 65px;
  }

  .wrapper {
    .projects-box {
      .fp-next, .fp-prev {
        background-size: 35%;
      }
    }
  }
  .wrapper .solution-block .cards-s .card-s .big-text-c{
    line-height: 1.25rem;
  }

  .wrapper .solution-block .container {
    //height: 39rem;
  }
  .wrapper .technology-group .container{
    padding: 0 17px;
  }
  .wrapper nav .navbar-nav .c-nav span,
  .wrapper nav .navbar-toggler.c-nav .contact-nav{
    max-width: 135px!important;
  }

  .popup-wrapper .container .slick-slider .investment-block .texts .head-text-next,
  .popup-wrapper .container .slick-slider .concept-slide .texts .text-list .item>div {
    font-size: 1.02rem;
  }

  .popup-wrapper.service-popup .container .p-slide .content-upfrond .list-items .item  {
    margin-top: 1.1rem;

    .texts {
      .title {
        font-size: 1.1rem;
      }
    }
  }

  .popup-wrapper.service-popup .container .p-slide .content-upfrond .list-items {
    .item {
      .texts {
        .title.title-center-lg {
          padding-top: 0;
        }
      }
    }
  }

  .wrapper .franchising-block .container .elements .element .box{
    img{
      margin-bottom: 0.8rem;
    }
    ul{
      margin-top: 7px;
      margin-bottom: 41px;
      li{
        line-height: 1.4rem;
      }
    }
  }
  .wrapper nav .navbar-nav .c-nav{
    max-width: 12.7rem;
  }
  .page-btn{
    max-width: 11.15rem;
  }
  .wrapper .slide .project-info .cont{
    padding: 0.89rem 1.21rem;
  }
  .wrapper .content-box .group-left div{
    max-width: 303px;
  }
  .wrapper .slide .shoutout-block{
    .text-high-slide, .text-ital-slide{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .text-high-slide{
      line-height: 1.4rem;
    }
    .text-ital-slide{
      max-width: 270px;
    }
  }
  .franchising-block .container{
    //margin-top: 9px;
  }

  .wrapper .slide .project-info .cont .box .text-small{
    margin-top: 0.47rem;
  }

  .wrapper .slider-btn .btn{
    max-width: 135px;
  }
  .wrapper nav .logo img{
    height: 4.1rem;
  }

  .wrapper {
    .contact-box .contact-block .container {
      .input-lay {
        //max-height: 3.9rem;

        .input-my {
          //max-height: 2.3rem;
          //min-height: 2.3rem;
          border-radius: 0;
          padding: 0 1rem;
        }

        .select-my {
          //max-height: 2.3rem;
          //min-height: 2.3rem;

          .dropdown-toggle:after {
            background-image: url('/assets/img/contact/arrow-select-small.png');
            background-size: 8px 5px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  nav .navbar-collapse .c-nav{
    display: block;
  }
  nav .container > .c-nav{
    display: none;
  }

  .wrapper .solution-block .container {
    padding-top: 0px;
  }
  .wrapper .slide .fp-tableCell {
    padding-top: 65px;
  }

  //.bg-help{
  //  display: none;
  //}
  .technology-group .container .block:nth-child(1){
    padding: 0 15px;
    .bg-box{
      margin-right: 2.4vw;
    }
  }
  .wrapper .technology-group .block.hidden-xm-down{
    display: flex;
  }
  .wrapper .solution-block {
    .cards-s{
      grid-template-columns: 1fr 1fr 1fr;
      .card-s:nth-child(3){
        display: flex;
      }
      .card-s:nth-child(2){
        display: flex;
      }
    }
    .line-text-block{
      .text-l-s{
        display: flex;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .wrapper .technology-group .container{
    padding: 0 33px;
  }
  .wrapper .franchising-block .container .elements{
    grid-template-columns: 1fr 1fr;
    //.element:nth-child(3){
    //  display: none;
    //}
  }

  .wrapper .contact-box .contact-block .container {
    .controls {
      .input-lay:nth-child(1) {
        display: none;
      }
    }
  }



}@include media-breakpoint-down(xl) {
   // QUERIES
 }

@include media-breakpoint-up(xl) {
  html{
    font-size: 17.5px;
  }
  .wrapper .slide .fp-tableCell,
  .stretch {
    padding-top: 100px;
  }

}
@media (max-height: 800px) and (min-width: 1367px)  {
  html{
    font-size: 15px;
  }
  .contact-block{
    .input-lay, .group-inputs-white, .group-radio-white, .dynamic-block, .controls{
      margin-bottom: .4rem!important;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-btn {
    display: none;
  }
}
