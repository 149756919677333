@import "../../../../index";
.select-modal{
  transition: 0.3s;
  opacity: 0;
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  .popup-lay{
    @extend  .container;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42rem;
    position: relative;
    .content{
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      padding: 1rem;
      height: 100%;
      width: 100%;
      .items{
        height: 100%;
        overflow: auto;
        .item{
          @extend .text-medium-small;
          font-family: 'Gilroy', sans-serif;
          cursor: pointer;
          color: rgba(38, 38, 38, 1);
          padding-right: .5em;
          padding-left: 0;
          width: 4rem;
          display: flex;
          white-space: nowrap;
          > div{
            width: 100%;
          }
          span{
            @extend .text-medium-small;
            font-family: 'Gilroy', sans-serif;
            color: grey;
            padding-right: .5em;
            padding-left: 0;
            width: 4rem;
            min-width: 60px;
          }
        }
      }
    }



    .close{
      position: absolute;
      top: 0;
      right: -2.85rem;
      cursor: pointer;
      img{
        height: 1.42rem;
        width: auto;
      }
    }
  }
}
.select-modal-open{
  display: flex;
  opacity: 1;
}
