@import "../../../index";
.exit-popup{
  transition: 0.3s;
  opacity: 0;
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  .popup-lay{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 809px;
    height: fit-content;
    //max-height: 805px;
    position: relative;
    .content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      height: 100%;
      width: 100%;
      max-width: 46.22rem;
      max-height: 100vh;
      overflow: auto;
      .header{
        height: fit-content;
        min-height: 5.77rem;
        background-color: #0a2360;
        display: flex;
        align-items: center;
        padding: 0 2.85rem;
        img{
          height: 2.57rem;
          margin-right: 1.65rem;
          width: auto;
        }
        .text{
          color: white;
          font-size: 1.25rem;
          font-weight: 600;
          font-family: 'Gilroy', sans-serif;
        }
      }
      .else{
        padding: 2rem 2.85rem .8rem 2.85rem;
        background: white;
        .text-blue{
          @extend .text-middle;
          padding-left: 1.08rem;
          margin-bottom: 1.14rem;
          color: #262626;
          font-family: 'Gilroy', sans-serif;
          position: relative;
        }
        .text{
          @extend .text-middle;
          color: #262626;
          margin-bottom: .3rem;
          margin-top: 1rem;
          font-family: 'Gilroy', sans-serif;
          position: relative;
        }
        .text-ital{
          @extend .text-medium-small;
          font-weight: 400;
          color: #808080;
          font-family: "Gilroy - Regular Italic", sans-serif;
          font-style: italic;
        }
        .text-blue:before{
          content: '';
          left: 0;
          position: absolute;
          height: 100%;
          width: 2px;
          background-color: #0a2360;
        }
        textarea{
          width: 100%;
          border: 1px solid #e6e6e6;
          padding: 1.02rem 1.31rem;
          font-family: "Gilroy", sans-serif;
          font-weight: 500;
          font-size: .91rem;
        }
        textarea::placeholder, .input-popup::placeholder{
          color: #808080;
          font-family: "Gilroy", sans-serif;
          font-weight: 500;
          font-size: .91rem;
        }
        .input-popup{
          height: 3.125rem;
          padding: 0 1.31rem;
          border: 1px solid #e6e6e6;
          border-radius: 0;
          font-family: "Gilroy", sans-serif;
          font-weight: 500;
          font-size: .91rem;
        }
        .input-popup:active, .input-popup:focus{
          box-shadow: none;
        }
        .elements{
          display: flex;
          margin-top: 1rem;
          align-items: center;
          justify-content: space-between;
          .groups{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .text{
              margin: 0;
            }
          }
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.91rem;
            font-family: "Gilroy", sans-serif;
            background: #0a2360;
            color: white;
            font-weight: 600;
            width: 9.98rem;
            height: 2.825rem;
            border: none;
          }
        }
      }
    }

    .close{
      position: absolute;
      top: 0;
      right: -2.85rem;
      cursor: pointer;
      img{
        height: 1.42rem;
        width: auto;
      }
    }
  }
  .errorMsg{
    display: block;
    color: rgba(254, 106, 106, 0.8);
    text-align: right;
    font-size: .91rem;
  }

  .hidden{
   display: none;
  }
  .input-error, .input-error:focus, .input-error:active{
    background: rgba(254, 106, 106, 0.8);
    color: white!important;
  }
  .input-error::placeholder{
    color: white!important;
  }
}
.unload-info-popup-open{
  display: flex;
  opacity: 1;
}
.exit-popup .popup-lay .content{
  .close{
    right: -3rem;
    top: 0;
  }
}
@include media-breakpoint-down(md) {

  .exit-popup .popup-lay .content{
    position: relative;
    .close{
      right: 1rem;
      top: 1rem;
    }
  }
  .exit-popup .popup-lay .content .header{
    flex-direction: column;
    align-items: flex-start;
    min-height: fit-content;
    img{
      margin: 1rem 0;
    }
    .text{
      margin-bottom: 1rem;
    }
  }
  .exit-popup .popup-lay .content .else .elements{
    flex-direction: column;
    align-items: flex-start;
    button{
      margin: 1rem 0;
    }
  }
}
@include media-breakpoint-only(lg) {
  .exit-popup .popup-lay .content .close{
    right: 5rem;
  }
}
.mobile .exit-popup{
  //display: none!important;
  .mobile-hidden, .popup-lay .content .else textarea{
    display: none;
  }
}
