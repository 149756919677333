/* HTML5 Boilerplate accessible hidden styles */
[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

[type="radio"] + span {
  display: flex; }

/* the basic, unchecked style */
[type="radio"] + span:before {
  content: '';
  display: inline-block;
  width: 1.2rem;
  min-width: 1.2rem;
  height: 1.2rem;
  vertical-align: -0.25rem;
  border-radius: 0.6rem;
  border: 0 solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.31);
  margin-right: 0.75rem;
  transition: 0.5s ease all; }

/* the checked style using the :checked pseudo class */
[type="radio"]:checked + span:before {
  background: white;
  border: 0.32rem solid rgba(38, 38, 38, 0.9);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.31); }
