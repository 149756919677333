@import "../../index";

.wrapper .navbar {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  > .container{
    height: 100%;
    position: relative;
  }
  .logo {
    img {
      //height: 4.2rem;
      //height: 3.1rem;
      //margin-top: 0.2rem;
    }
  }

  .navbar-brand {
    margin-right: 2.5rem;
  }

  .navbar-toggler {
    background-color: white;
    padding: 0;
  }
  .navbar-toggler-icon{
    background-image: none!important;
  }
  button.navbar-toggler{
    background: none;
    border: none;
    color: #031c60;
    span{
      background-image: none;
      font-family: "Font Awesome 5 Free",  sans-serif;
      font-weight: 900;
      font-size: 25px;
      margin-top: 11px;
    }
    span:before{
      content: '\f00d';
      color: #031c60;
    }
  }
  button.navbar-toggler.collapsed span:before{
    content: '\f0c9';
  }
  .navbar-toggler.c-nav{
    position: absolute;
    right: 63px;
    top: auto;
    max-width: 13.1rem;
    //max-width: 10.8rem;
    background: none;
    border: none;
    width: 100%;
    min-width: 140px;
    .contact-nav {
      margin-left: auto;
      font-family: "Gotham Pro", sans-serif;
      height: 2.86rem;
      color: white;
      width: 100%;
      font-size: 0.78rem;
      line-height: 2rem;
      align-items: center;
      border: 2px solid #818285;
      display: flex;
      justify-content: center;
      font-weight: 700;
      background-color: #818285;
      text-shadow: none;
      border-radius: 0;
      margin-top: 0;
    }
  }

  .navbar-nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;


    .nav-link.active {
      position: relative;
    }

    .active:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 2px;
      background: #0a2360;
      bottom: -.4rem;
      left: 0;
    }
    .c-nav.active:after{
      content: none;
    }
    .nav-item.nav-link {
      @extend .menu-text;
      //height: 1.4rem;
      height: 1rem;
      text-decoration: none;
      color: #262626;
      font-family: "Gotham Pro", sans-serif;
      padding: 0;
      white-space: nowrap;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      margin: 0 0 0 1.65rem;
    }

    .nav-link:hover, .nav-link:focus {
      color: #000000;
    }
    .c-nav{
      margin: 0;
      padding: 0 0 0 0.5rem;
      max-width: 13.1rem;
      //max-width: 10.8rem;
      width: 100%;
      min-width: 140px;
      margin-left: 0.8rem!important;
      span{
        max-width: 198.75px;
        //max-width: 198.75px;
      }
    }
    .contact-nav {
      @extend .button-medium;
      font-family: "Gotham Pro", sans-serif;
      height: 2.86rem;
      color: white;
      width: 100%;
      line-height: 2rem;
      align-items: center;
      border: 2px solid #818285;
      display: flex;
      justify-content: center;
      background-color: #818285;
      text-shadow: none;
      border-radius: 0;
      margin-top: -0.9rem;
    }

    .c-nav.active {
      border-bottom: none;

      span {
        font-weight: 700;
      }
    }
  }
}

// menu modal
.wrapper .navbar-collapse.show, .collapsing{
  @extend .container;
  transition: 0.3s;
  height: auto;
  width: 100vw;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  .navbar-nav{
    background: rgba(255, 255, 255, 0.9);
    max-width: 215px;
    top: 0;
    right: -15px;
    position: absolute;
    display: flex;
    align-items: flex-start;
    .nav-item{
      margin: 9px .5rem;
      font-weight: 500;
    }
    .bg-help{
      transition: 0s;
      position: absolute;
      background: rgba(255, 255, 255, 0.9);
      height: 100%;
      width: 100%;
      left: 100%;
      top: 0;
    }
    .c-nav{
     margin: 0!important;
      .contact-nav{
        margin: 0 auto;
      }
    }
    .c-nav{
      display: none;
    }
  }
}
.wrapper .collapsing{
  right: -100%;
}
.wrapper .navbar .navbar-nav.nav-mobile{
  display: none;
}
@include media-breakpoint-down(md) {
  .wrapper .navbar .container{
    max-width: 97%;
  }
  .wrapper .navbar .navbar-nav.nav-mobile{
    display: flex;
  }
  .wrapper .navbar .navbar-nav.nav-desctop{
    display: none;
  }
}
