
.custom-radios div {
  display: inline-block;
}
.custom-radios input[type="radio"] {
  display: none;
}
.custom-radios input[type="radio"] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.custom-radios input[type="radio"] + label span {
  display: inline-block;
  width: .84rem;
  height: .84rem;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #b3b3b3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
}
.custom-radios input[type="radio"]:checked + label span {
  opacity: 1;
  background: url('/assets/img/check.png') no-repeat center;
  background-color: #b3b3b3;
  width: .84rem;
  height: .84rem;
  display: inline-block;
}
.custom-radios{
  label{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 .17rem 0;
    span{
      margin: 0!important;
    }
    .c-text{
      margin-left: .57rem;
      font-size: .91rem;
      font-weight: 600;
      color: #808080;
      font-family: "Gilroy", sans-serif;
    }
  }
}
