@import "../../index";

.contact-box{
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url('/assets/img/contact/bg-min.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.5s;
  .fp-scrollable{
    width: 100%;
  }
  .contact-block{
    @extend .stretch;
    height: 100%;
    width: 100%;
    .container{
      height: 100%;
      display: flex;
      justify-content: center;
      form{
        //padding: 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
      .input-lay{
        margin-bottom: 1.48rem;
        height: 100%;
        max-height: 4.485rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .text{
          @extend .text-medium-small;
          font-family: 'Gilroy', sans-serif;
          color: white;
          min-height: 1.4rem;
        }
        .errorMsg{
          color: rgba(254, 106, 106, 0.8);
          min-height: 0;
          height: 0;
          text-align: right;
        }
        .select-my{
          min-height: 2.857rem;
        }
        .input-error{
          background: rgba(254, 106, 106, 0.8);
          color: white;
        }
        .input-error::placeholder{
          color: white;
        }
        input{
          background: rgba(255, 255, 255, 0.8);
        }
      }
      .radio-select{
        padding: 0;
      }
      .lay-textarea{
        max-height: 20rem;
      }

      .radio-group{
        height: 100%;
        //max-height: 3.42rem;
        max-height: 5.42rem;
        label{
          background-color: rgba(38, 38, 38, 0.8);
          height: 100%;
          max-height: 4rem;
          min-height: 3.08rem;
        }
        .text{
          min-height: 1.88rem;
        }
      }
      .group-labels{
        max-height: 4.485rem;
        margin-bottom: 1.48rem;
        width: 100%;
        display: flex;
        height: 100%;
        .input-lay{
          padding: 0 .57rem;
          //padding: 0 .57rem;
          //padding: 0 1.14rem;
        }
        .input-lay:nth-child(1){
          padding-left: 0;
        }
        .input-lay:nth-last-child(1){
          padding-right:0;
        }
      }

      .group-inputs-white,.group-radio-white{
        margin-bottom: 1.48rem;
        width: 100%;
        display: flex;
        height: 100%;
        max-height: 4.485rem;
        .input-lay{
          padding: 0 .57rem;
        }
        .input-lay:nth-child(1){
          padding-left: 0;
        }
        .input-lay:nth-last-child(1){
          padding-right: 0;
        }
      }
      .group-radio-white{
        max-height: 5.42rem;
        label{
          cursor: pointer;
          margin-bottom: 0;
          padding: 0 1.2rem;
          display: flex;
          align-items: center;
          span{
            @extend .text-medium;
            font-family: 'Gilroy', sans-serif;
            color: white;
          }
        }
      }
      .dynamic-block{
        margin-bottom: 1.48rem!important;
        min-height: 20rem;
        max-height: 20rem;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.5s;
        position: relative;
        .moving-block{
          transform: translate3d(0, 0px, 0px);
          transition: 0.5s;
          width: 100%;
        }
        .franchise-block{
          position: absolute;
          left: -100%;
          top: 0;
          height: 100%;
          width: 100%;
        }
        .partnership-block{
          position: absolute;
          left: -220%;
          top: 0;
          height: 100%;
          width: 100%;
          //.input-lay{
          .input-lay.col-6 {
            padding-right: .57rem;
          }
          //.select-my{
          //  padding-right: .57rem;
          //}
        }
        .forward-information-block{
          position: absolute;
          left: -340%;
          top: 0;
          height: 100%;
          width: 100%;
          .input-lay.col-6 {
            padding-right: .57rem;
          }
        }
        .inquiry-block{
          position: absolute;
          left: -460%;
          top: 0;
          height: 100%;
          width: 100%;
          .lay-textarea{
            max-height: 100%;
          }
          textarea{
            max-height: 20rem;
          }
        }
      }
      .controls{
        height: 100%;
        max-height: 4.485rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1.48rem;
        .input-lay-attachments {
          display: none;
        }
        .input-lay{
          padding: 0;
          margin-bottom: 0;
        }
        .input-lay:nth-child(1){
          .page-btn{
            max-width: 16.85rem;
            background-color: rgba(38, 38, 38, 0.8);
            border: none;
            button{
              @extend .text-medium-small;
              padding: 0 1.14rem;
              text-align: left;
              text-transform: none;
              font-family: 'Gilroy', sans-serif;
              border-radius: 0;
              color: white;
              border-color: rgba(38, 38, 38, 0.8);
            }
          }
        }
        .input-lay:nth-child(2){
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          button{
            border-radius: 0;
            color: white;
            border-color: white;
          }
          .page-btn{
            border: none;
          }
        }
      }
      .footer{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        .line{
          @extend .footer-text;
          color: #cccccc;
          font-family: 'Gilroy', sans-serif;
          line-height: 1.37rem;
          span{
            @extend .footer-text;
            font-family: 'Gilroy', sans-serif;
            text-decoration: underline;
            color: #cccccc;
            padding: 0 0.12rem;
            // href disabled
            cursor: pointer;
            //pointer-events: none;
          }
          span:nth-child(1){
            padding-left: 0;
          }
        }
        .line:nth-child(2){
          cursor: pointer;
        }
        .language{
          color: #cccccc;
          @extend .footer-text;

          span{
            @extend .footer-text;
            font-family: 'Gilroy', sans-serif;
            text-decoration: underline;
            //pointer-events: none;
            cursor: pointer;
            color: #cccccc;

            &.social {
              color: #aaaaaa;
              padding-right: 0.5rem;
            }
          }
          img {
            @extend .footer-text;
            height: 1.1em;
            margin-bottom: 0.2em;
            margin-right: 0.2em;
            &.language {
              padding-left: 0.5rem;
            }
          }

        }
      }
    }
  }
  textarea{
    height: 100%;
    min-height: 4.28rem;
    max-height: 20rem;
    background: rgba(255, 255, 255, 0.8);
    padding: 1.08rem 1.2rem;
    border: none;
  }
  textarea::placeholder{
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
  }
}
.hidden_attachment{
  display: none;
}
.attach_block{
  min-width: 152px;
  //margin-top: .78rem;
}
@include media-breakpoint-only(xl) {
  .radio-group{
    max-height: 3.42rem!important;
  }
  .contact-box .contact-block{
    height: 100%!important;
    .container{
      #ajaxForm{
        height: 100%;
        padding: 2rem 0;
      }
      .dynamic-block{
        min-height: 0;
        //max-height: 100%;
        height: 100%;
      }
    }
  }
}
@include media-breakpoint-down(xl) {
  .contact-box .contact-block .container{
    //max-height: calc(100vh - 100px);
  }
  .contact-box .contact-block .container .radio-select{
    display: none;
  }
}


.mobile-revert{
  .contact-box #ajaxForm .controls .input-lay:nth-child(1){
    display: none;
  }
  .contact-box{
    .contact-block .container {
      .radio-select{
        display: flex;
      }
      #ajaxForm .group-radio-white{
        display: none;
      }
      .franchise-block{
        .lay-textarea{
          height: fit-content;
        }
      }
    }
  }
  .contact-box .contact-block .container .controls .input-lay:nth-child(2){
    align-items: flex-start;
  }
  .contact-box .contact-block .container{
    max-height: 100%;
  }
  .wrapper {
    .contact-box .contact-block{
      display: flex;
      align-items: center;
      padding-top: 66px;
      .container{
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        position: relative;
        #ajaxForm{
          justify-content: center;
          padding: 0;
          //position: absolute;
          top: 0;
          height: auto;
          min-height: 100%;
          .dynamic-block {
            //max-height: fit-content;
            .franchise-block, .partnership-block,.forward-information-block,.inquiry-block{
              min-width: 100%;
              max-width: 100%;
            }
            .franchise-block{
              .lay-textarea{
                display: none;
              }
            }
            .moving-block.franchising-active{
              .lay-textarea{
                display: flex;
                height: fit-content;
              }
            }
            .lay-textarea{
              height: 100%;
            }

            .partnership-block{
              .input-lay{
                max-width: 100%;
                min-width: 100%;
                padding-right: 0;
              }
            }
          }
        }
        .group-inputs-white{
          margin-bottom: 0;
          flex-direction: column;
          height: auto;
          max-height: 100%;
          .input-lay{
            position: inherit;
            max-width: 100%;
            padding: 0;
            .rrs__button + .rrs__options{
              top: auto;
              bottom: auto;
              &:before{
                content: none;
                display: none;
              }
            }
          }
        }
        .group-radio-white{
          height: auto;
          max-height: 8.7rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1rem;
          .radio-group{
            max-width: 100%;
            padding-left: 0;
            margin-bottom: 0;
            padding-right: 0;
            label{
              height: 3rem;
            }
          }
          .radio-group:not(:first-child){
            .text{ min-height: .6rem }
          }
        }
        .dynamic-block{
          margin-top: .78rem;
        }
        .page-btn{
          display: flex;
        }
      }
    }
  }
  .input-lay{
    position: relative!important;
    .select-wrapp{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@include media-breakpoint-down(md) {
  .contact-box .fp-scrollable, .fp-scroller{
    overflow: visible;
  }
  .wrapper .contact-box .contact-block .container .dynamic-block{
    height: 28rem;
    min-height: 28rem!important;
    max-height: 28rem!important;
  }
  .contact-box{
    .contact-block .container {
      .radio-select{
        display: flex;
      }
      #ajaxForm .group-radio-white{
        display: none;
      }
      .franchise-block{
        .lay-textarea{
          height: fit-content;
        }
      }
    }
  }
  .contact-box .contact-block .container .controls .input-lay:nth-child(2){
    align-items: flex-start;
  }
  .contact-box .contact-block .container{
    max-height: 100%;
  }
  .wrapper {
    .contact-box .contact-block{
      display: flex;
      align-items: center;
      padding-top: 66px;
      .container{
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        position: relative;
        #ajaxForm{
          justify-content: center;
          padding: 0;
          //position: absolute;
          top: 0;
          height: auto;
          min-height: 100%;
          .dynamic-block {
            //max-height: fit-content;
            .franchise-block, .partnership-block,.forward-information-block,.inquiry-block{
                  min-width: 100%;
                  max-width: 100%;
            }
            .franchise-block{
              .lay-textarea{
                display: none;
              }
            }
            .moving-block.franchising-active{
              .lay-textarea{
                display: flex;
                height: fit-content;
              }
            }
            .lay-textarea{
              height: 100%;
            }

            .partnership-block{
              .input-lay{
                max-width: 100%;
                min-width: 100%;
                padding-right: 0;
              }
            }
          }
        }
        .group-inputs-white{
          margin-bottom: 0;
          flex-direction: column;
          height: auto;
          max-height: 100%;
          .input-lay{
            position: inherit;
            max-width: 100%;
            padding: 0;
            .rrs__button + .rrs__options{
              top: auto;
              bottom: auto;
              &:before{
                content: none;
                display: none;
              }
            }
          }
        }
        .group-radio-white{
          height: auto;
          max-height: 8.7rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1rem;
          .radio-group{
            max-width: 100%;
            padding-left: 0;
            margin-bottom: 0;
            padding-right: 0;
            label{
              height: 3rem;
            }
          }
          .radio-group:not(:first-child){
            .text{ min-height: .6rem }
          }
        }
        .dynamic-block{
          margin-top: .78rem;
        }
        .page-btn{
          display: flex;
        }
      }
    }
  }
  .input-lay{
    position: relative!important;
    .select-wrapp{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@include media-breakpoint-down(sm) {
  .contact-block{
    display: flex;
    align-items: center;
    min-height: 100%;
  }
  .wrapper .contact-box .contact-block {
    display: flex;
    align-items: center;
    .container{
      height: auto;
      padding: 0 17px;
      position: relative;
      #ajaxForm{
        //padding: 0 17px;
        //position: inherit;
        top: 0;
        height: auto;
        >.input-lay:nth-child(1){
          min-height: 59px;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
        .input-lay{
          min-height: 3.5rem;
        }
        .franchise-block{
          position: relative!important;
          .group-labels{
            max-width: 516px;
            height: fit-content;
            min-height: max-content;
            display: grid;
            grid-template-columns: 1fr;
            //grid-column-gap: 1rem;
            .input-lay{
              padding: 0;
              min-width: 100%;
            }
          }
        }
        .controls{
          min-height: 100px;
          display: flex;
          flex-direction: column;
          .input-lay{
            max-width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .input-lay:nth-child(2){
            .text{
              min-height: 0;
            }
          }
        }
      }
    }
    .footer{
      flex-direction: column;
      align-items: flex-start;
      pointer-events: none;
    }
  }
  [type="radio"] + span:before{
    //position: absolute;
    //left: 1rem;
    //top: auto;
  }
  [type="radio"] + span{
    display: flex;
    align-items: center;
  }
}
.select-error{
  .rrs__button{
    background: none;
    .rrs__label{
      background: rgba(254, 106, 106, 0.8)!important;
      span{
        color: white;
      }
      .caret-icon{
        fill: white;
      }
    }
  }
}
