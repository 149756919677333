@import "../../../index";

.popup-wrapper.service-popup{
  &.service-popup-active {
    display: flex;
  }
  .container{
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    .p-slide,.slick-list,.slick-track{
      height: 100%!important;
    }
    .slick-slider{
      height: 100%;
      max-height: 43.5rem;
    }
    .elements{
      width: 100%;
      height: 100%;
      max-height: 43.5rem;
      position: relative;
    }
    .p-slide{
      background-color: #f7f7f7;
      .content{
        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
          padding-bottom: 1.25rem;
        }
      }
      .content-upfrond{
        height: 100%;

        .c-content {
          height: 34rem;
          margin: 1.5rem 0 2rem 0;
          padding: 2.4rem;
          display: flex;
          background: white;
          box-shadow: 0 0 21px rgba(0, 0, 0, 0.07);

          .photo {
            width: 38.5%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            &.photo1 {
              background-image: url("/assets/img/popup/service/photo1.png");
            }

            &.photo2 {
              background-image: url("/assets/img/popup/service/photo2.png");
            }

            &.photo3 {
              background-image: url("/assets/img/popup/service/photo3.png");
            }

            .photo-caption {
              @extend .header-bold;
              background-color: rgba(255,255,255,0.8);
              height: 2.7rem;
              text-align: center;
              line-height: 2.7rem;
            }
          }
        }

        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
        }
        .text-blue {
          @extend .header-medium;
          margin-top: 0.7rem;
          margin-bottom: 2rem;
          padding-left: 2rem;
          color: #262626;
          position: relative;
        }

        .text-blue:before {
          content: '';
          height: 100%;
          width: 2px;
          background-color: #0a2360;
          position: absolute;
          top: 0;
          left: 0;
        }

        .list-items{
          height: 100%;
          width: 61.5%;
          padding: 0 0 0 2.8rem;
          .item{
            max-height: 8rem;
            margin-top: 2rem;
            display: flex;
            .big-number{
              display: flex;
              justify-content: flex-start;
              width: 100%;
              max-width: 4.85rem;
              font-family: "Gilroy", sans-serif;
              font-weight: 400;
              color: #262626;
              font-size: 2.74rem;
              line-height: 2.74rem;
            }
            .texts{
              width: 120%;
              .title{
                @extend .text-bold;
                font-family: "Gilroy", sans-serif;
                color: #262626;
                margin-bottom: .5rem;

                &.title-center {
                  padding-top: 0.5rem;
                }
              }
              .list{
                .line{
                  @extend .text-medium-small;
                  font-family: "Gilroy", sans-serif;
                  padding-left: .6rem;
                  position: relative;
                  color: #262626;
                }
                .line:before{
                  content: '';
                  position: absolute;
                  top: 0.5rem;
                  left: 1px;
                  width: .228rem;
                  height: .228rem;
                  border-radius: 10px;
                  background-color: #262626;
                }
              }
            }
            .white-text{
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 2.228rem;
              border-left: 1px solid #e6e6e6;
              .line{
                @extend .text-medium-small;
                font-family: "Gilroy", sans-serif;
                color: #808080;
                font-weight: 600;
              }
            }
          }
        }
        .slider-footer{
          font-family: "Gilroy", sans-serif;
          color: #666666;
          font-size: .685rem;
          line-height: .8rem;
          padding-top: .7rem;
          font-weight: 400;
        }
      }
    }
  }
}
