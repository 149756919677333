@import "../../../index";
@import '../technology/technology';
.project-wrapper{
  @extend .technology-popup;
  @extend .technology-popup-active;
}
.p-slide {
  padding: 1.48rem 3.42rem;
  background: white;
  //height: 34.6rem;
  height: 38.8rem;
  width: 100% !important;

  .photo {
    img {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, .18);
    }
  }

  .s-content {
    display: flex;

    .p-elements {
      padding-top: 1.25rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .head-text {
        @extend .header-bold;
        font-family: "Gilroy", sans-serif;
        margin-bottom: 2.5rem;
        padding-right: 3.5rem;
        font-size: 1.2rem;
      }

      .b-text {
        @extend .header-medium;
        position: relative;
        color: #262626;
        font-family: 'Gilroy', sans-serif;
        padding-left: 1.6rem;
        margin-bottom: 2rem;
        padding-right: 3.5rem;
      }

      .b-text:before {
        content: '';
        border: 1px solid #0a2360;
        background: #0a2360;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
      }

      .text-list {
        margin-bottom: 2.5rem;

        .item {
          font-family: 'Gilroy', sans-serif;
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          img {
            margin-right: .8rem;
            width: 1.25rem;
            height: auto;
          }

          > div {
            font-family: "Gilroy", sans-serif;
            @extend .text-medium;
          }
        }
      }
    }
  }

  .blue-text {
    margin-top: 1.71rem;
    position: relative;
    color: #262626;
    font-family: 'Gilroy', sans-serif;
    padding-left: 1.6rem;
    margin-bottom: 2rem;

    .head-text, .item {
      @extend .header-medium;
      color: #262626;
      font-family: 'Gilroy', sans-serif;
    }

    .item {
      padding-left: .97rem;
      position: relative;
    }

    .item:before {
      content: '';
      position: absolute;
      top: .5rem;
      left: 1px;
      width: .34rem;
      height: .34rem;
      border-radius: 10px;
      background-color: #262626;
    }
  }

  .blue-text:before {
    content: '';
    border: 1px solid #0a2360;
    background: #0a2360;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
  }

  .back-line {
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
    color: #808080;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-right: .57rem;
      height: fit-content;
    }
  }
}
.slider-footer{
  font-family: "Gilroy", sans-serif;
  color: #666666;
  font-size: .685rem;
  line-height: .8rem;
  padding-top: .7rem;
  font-weight: 400;
}
.economical-slide {
  .photo {
    margin-top: 0.5rem;
  }
}

.traditional-slide {
  .head-text{
    @extend .header-bold;
    font-family: "Gilroy", sans-serif;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: .6rem;
    margin-bottom: .3rem;
  }
  .photo {
    padding-top: 1.14rem;

    img {
      max-width: 100%;
      //width: 100%;
      //height: fit-content;
      //min-height: 315px;
    }
  }
}

.concept-slide {
  .c-content {
    height: 100%;
    display: flex;
    padding-bottom: 1.37rem;
  }

  .photos {
    flex-direction: column;
    display: flex;
    width: 60%;
    height: 96%;

    .photo {
      width: 100%;
      height: 100%;
      background-image: url("/assets/img/popup/photo2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .photo:nth-child(1) {
      background-image: url("/assets/img/popup/photo.png");
      margin: 1.2rem 0;
    }
  }

  .texts {
    width: 50%;
    padding: 1.2rem 0 1.2rem 3.48rem;

    .head-text {
      @extend .header-bold;
      font-family: "Gilroy", sans-serif;
      margin-bottom: 1.78rem;
      font-size: 1.2rem;
    }

    .text-list .item {
      margin-bottom: 1rem;
      display: flex;

      img {
        //margin-top: .2rem;
        width: auto;
        height: 1.25rem;
        margin-right: .74rem;
      }

      > div {
        @extend .text-medium;
        font-weight: 700;
        font-family: 'Gilroy', sans-serif;
        color: #262626;
        display: flex;
        flex-direction: column;

        small {
          @extend .text-medium-small;
          color: #808080;
          font-family: 'Gilroy', sans-serif;
          display: flex;
          flex-direction: column;
          margin-top: .3rem;

          span {
            @extend .text-medium-small;
            color: #808080;
            font-family: 'Gilroy', sans-serif;
            padding-left: .74rem;
            position: relative;

            strong {
              @extend .text-bold-small;
            }
          }

          span:before {
            content: '';
            position: absolute;
            width: .228rem;
            height: .228rem;
            border-radius: 10px;
            top: .4rem;
            left: 0;
            background-color: #808080;
          }
        }
      }
    }
  }
}

.investment-block{
  .texts{
    .head-text ,.head-text-next{
      @extend .header-bold;
      font-family: "Gilroy", sans-serif;
      max-width: 75%;
      color: #262626;
      font-size: 1.2rem;
    }
    .head-text{
      margin-top: 1.94rem;
    }
    .head-text-next{
      margin-top: 2.05rem;
      margin-bottom: 1.48rem;
      font-size: 1.02rem;
    }
    .table{
      border: 1px solid #f2f2f2;
      border-top: none;
      thead{
        background-color: #0a2360;
        height: 3.4rem;
        th{
          @extend .text-semibold;
          font-family: 'Gilroy', sans-serif;
          color: white;
          padding: 0 1.7rem;
          vertical-align: middle;
          background-color: #0a2360;
          text-align: center;
          >div{
            @extend .text-bold;
            position: relative;
            font-family: 'Gilroy', sans-serif;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #0a2360;
            padding: 0 1.8rem;
            background-color: white;
            height: 3.4rem;

            img {
              position: absolute;
              left: 1.8rem;
              height: 2rem;
            }
          }
        }
        th:nth-child(1){
          text-align: left;
        }
        th:nth-last-child(1){
          padding: .17rem;
        }
      }
      tbody{
        td{
          @extend .text-medium-small;
          font-family: 'Gilroy', sans-serif;
          padding: 0.9rem 1.7rem;

          strong {
            @extend .text-bold-small;
          }
        }
        td:nth-child(2),td:nth-child(3){
          width: 16.57rem;
          text-align: center;
        }
        td:nth-child(3){
          background-color: #fafafa;
        }
        //tr:nth-child(even){
        //  background-color: #f0f0f0;
        //}
        tr:nth-child(1){
          border-top: none;
          td{
            border-top: none;
          }
        }
      }
    }
  }
}
