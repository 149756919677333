@import "../../../../index";

.contact-popup{
  transition: 0.3s;
  opacity: 0;
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  .popup-lay{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44.11rem;
    height: 30.62rem;
    background: white;
    position: relative;
    .success{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      > img{
        height: 5.02rem;
        width: auto;
      }
    }
    .head-text{
      font-family: "Gilroy", sans-serif;
      color: #262626;
      font-size: 1.257rem;
      font-weight: 700;
      margin: 2.51rem 0;
    }
    .back-line {
      @extend .text-medium-small;
      font-family: 'Gilroy', sans-serif;
      color: #808080;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: .57rem;
        height: fit-content;
      }
    }
    .close{
      position: absolute;
      top: 0;
      right: -2.85rem;
      cursor: pointer;
      img{
        height: 1.42rem;
        width: auto;
      }
    }
  }
}
.contact-popup-open{
  display: flex;
  opacity: 1;
}

