@import "../../../index";

.popup-wrapper.franchasing-popup{
  .container{
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    .p-slide,.slick-list,.slick-track{
      height: 100%!important;
    }
    .slick-slider{
      height: 100%;
      max-height: 44.686rem;
    }
    .elements{
      width: 100%;
      height: 100%;
      max-height: 44.686rem;
      position: relative;
    }
    .p-slide{
      background-color: #f7f7f7;
      .content{
        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
          padding-bottom: 1.25rem;
        }
        .block-list{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 1.14rem;
          .item{
            background-image: url(/assets/img/popup/franchising/flag-bg.png);
            background-color: rgba(0, 0, 0, 0.62);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            //width: 20.57rem;
            width: 100%;
            height: 17.14rem;
            display: flex;
            .elm{
              margin: .857rem;
              padding: 1.428rem;
              background: rgba(255, 255, 255, 0.93);
              height: auto;
              width: 100%;
              .logo-line{
                display: flex;
                align-items: center;
                justify-content: space-between;
                img:nth-child(1){
                  height: 2.97rem;
                  width: auto;
                }
                .flag-img{
                  height: 2rem;
                  width: auto;
                }
                .p-data{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  padding-left: 1.02rem;
                  .name{
                    @extend .text-bold;
                    font-family: 'Gilroy', sans-serif;
                    color: #262626;
                  }
                  .location{
                    @extend  .text-medium-small;
                    color: #808080;
                    font-family: 'Gilroy', sans-serif;

                  }
                }
              }
              .text-items{
                padding-top: .97rem;
                p{
                  @extend  .text-medium-small;
                  font-weight: 600;
                  position: relative;
                  font-family: 'Gilroy', sans-serif;
                  color: #262626;
                  margin: 0;
                  padding-left: .57rem;
                  padding-bottom: .5rem;
                }
                p:before{
                  content: '';
                  position: absolute;
                  background-color: #262626;
                  height: .228rem;
                  width: .228rem;
                  border-radius: 10px;
                  top: .45rem;
                  left: 0;
                }
              }
            }
          }
          .item:nth-child(2){
            background-image: url(/assets/img/popup/franchising/flag-bg2.png);
          }
          .item:nth-child(3){
            background-image: url(/assets/img/popup/franchising/flag-bg3.png);
          }
          .item:nth-child(4){
            background-image: url(/assets/img/popup/franchising/flag-bg4.png);
          }
          .item:nth-child(5){
            background-image: url(/assets/img/popup/franchising/flag-bg5.png);
          }
          .item:nth-child(6){
            background-image: url(/assets/img/popup/franchising/flag-bg6.png);
          }
        }
      }
      .content-upfrond{
        height: 100%;
        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
        }
        .list-items{
          height: 100%;
          .item{
            height: 100%;
            max-height: 8rem;
            margin-top: 1.142rem;
            padding: 1.2rem 0;
            display: flex;
            background: white;
            box-shadow: 0 0 21px rgba(0, 0, 0, 0.07);
            .big-number{
              display: flex;
              justify-content: center;
              width: 100%;
              max-width: 6.85rem;
              font-family: "Gilroy", sans-serif;
              font-weight: 400;
              color: #262626;
              font-size: 2.74rem;
              line-height: 2.74rem;
            }
            .texts{
              width: 120%;
              .title{
                @extend .text-bold;
                font-family: "Gilroy", sans-serif;
                color: #262626;
                margin-bottom: .5rem;
              }
              .list{
                .line{
                  @extend .text-medium-small;
                  font-family: "Gilroy", sans-serif;
                  padding-left: .6rem;
                  position: relative;
                  color: #262626;
                }
                .line:before{
                  content: '';
                  position: absolute;
                  top: 0.5rem;
                  left: 1px;
                  width: .228rem;
                  height: .228rem;
                  border-radius: 10px;
                  background-color: #262626;
                }
              }
            }
            .white-text{
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 2.228rem;
              border-left: 1px solid #e6e6e6;
              .line{
                @extend .text-medium-small;
                font-family: "Gilroy", sans-serif;
                color: #808080;
                font-weight: 600;
                i{
                  @extend .text-medium-small;
                  font-family: "Gilroy", sans-serif;
                  color: #808080;
                  font-weight: 600;
                }
              }
            }
          }
        }
        .slider-footer{
          font-family: "Gilroy", sans-serif;
          color: #666666;
          font-size: .685rem;
          line-height: .8rem;
          padding-top: .7rem;
          font-weight: 400;
        }
      }
      .content-hospitality{
        .head-text{
          @extend .header-bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          padding-top: .52rem;
          font-family: "Gilroy", sans-serif;
          color: #262626;
        }
        .table {
          margin-top: 1.142rem;
          border: 1px solid #f2f2f2;
          thead{
            tr {
              th{
                border: none;
                img{
                  height: 5.14rem;
                  width: auto;
                }
              }
              th:nth-child(1){
                width: 100%;
              }
            }
            tr:nth-child(1){
              background-color: #0a2360;
              color: white;
              th{
                padding-top: .6rem;
                padding-bottom: .6rem;
              }
              div{
                @extend .text-medium;
                display: flex;
                font-family: "Gilroy", sans-serif;
                flex-direction: column;
                line-height: .91rem;
                color: white;
                small{
                  font-family: "Gilroy", sans-serif;
                  @extend .text-medium-small;
                  color: white;
                  font-weight: 300;
                  margin-top: .2rem;
                }
              }
            }
          }
          tbody{
            td{
              @extend .text-medium-small;
              padding-top: 0;
              padding-bottom: 0;
              color: #262626;
              height: 2.28rem;
              vertical-align: middle;
              font-family: "Gilroy", sans-serif;
              border-bottom: 1px solid #f2f2f2;
              border-top: none;
              strong{
                @extend .text-medium-small;
                color: #262626;
                font-family: "Gilroy", sans-serif;
                font-weight: 700;
              }
            }
            .t-title{
              td{
                @extend .text-bold-small;
                background-color: #f0f0f0;
                font-family: "Gilroy", sans-serif;
                border: none;
              }
            }
          }
        }
      }
    }
    .hospital-slider{
      background: white;
    }
  }
}
