@import "../../index";
.wrapper .vision-block{
  @extend .stretch;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .container{
    overflow: hidden;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .vision-collage{
    margin-bottom: 1.14rem;
    display: flex;
    .img-block{
      transition: 0.3s;
      overflow: hidden;
      cursor: pointer;
      max-height: 18.28rem;
      position: relative;
      img{
        transition: 0.3s;
        width: 100%;
        height: 100%;
      }
      .text{
        @extend .header-middle;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 2.57rem;
        color: #262626;
        font-family: Gilroy, sans-serif;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 0 1.3rem;
        min-width: 174px;
      }
    }
    .img-block:hover{
      img{
        transition: 0.3s;
        width: 105%;
        height: 105%;
        margin-left: -5%;
        margin-top: -1%;
      }
    }
    .img-block:nth-child(1){
      margin-right: 1.14rem;
    }
  }
  .vision-slide{
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    .container{
      .slide img{
        width: 100%;
        height: auto;
        max-height: 100%;
      }
      //.fp-scrollable{
      //  @extend .container;
      //}
      .fp-scroller{
        display: flex;
        align-items: center;
      }
      .fp-controlArrow{
        display: none;
        background: none;
        color: rgba(255, 255, 255, 0.6);
        font-size: 34px;
      }
      .fp-prev{
        left: 50px;
      }
      .fp-prev:before{
        content: '';
        background-image: url('/assets/img/left.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 1.14rem;
        height: 2.11rem;
      }
      .fp-next{
        right: 50px;
      }
      .fp-next:before{
        content: '';
        background-image: url('/assets/img/rigth.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 1.14rem;
        height: 2.11rem;
      }
    }
    .close-vision{
      cursor: pointer;
      position: absolute;
      top: 4vh;
      right: 50px;
      height: 1.42rem;
      img{
        height: 100%;
        width: auto;
      }
    }
    .count{
      width: 100%;
      margin: -1rem  .285rem 0 .285rem;
      position: relative;
      height: 40.75rem;
      max-height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .page-btn{
        color: white;
        border-color: white;
        position: absolute;
        bottom: 1.71rem;
        right: 1.71rem;
        .btn{
          color: white;
        }
      }
    }
    .text{
      @extend .header-middle;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 2.57rem;
      color: #262626;
      font-family: Gilroy, sans-serif;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 0 .685rem;
    }
  }
  .vision-slide-active{
    display: block;
  }
}
.vision-slide .fp-slidesContainer{
  transition: 0s!important;
}


@include media-breakpoint-down(md) {
  .wrapper {
    .vision-block{
      min-height: 100vh;
    }
    .vision-box .fp-tableCell .vision-block{
      .container{
        width: 1721px;
        max-height: 100%;
        overflow-y: auto;
        pointer-events: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .container .vision-collage{
        height: 100%;
        flex-direction: column;
        width: 100%;
        max-height: 45%;
        .img-block{
          min-height: 130px;
          height: 100%;
          width: 100%;
          margin-right: 0;
          img{
            display: none;
          }
        }
        .img-block:nth-child(1){
          margin-bottom: 1.14rem;
        }
      }
      .vision-collage:nth-child(1){
        .img-block:nth-child(1){
          background: url('/assets/img/vision/image-min.png') no-repeat center;
          background-size: cover;
        }
        .img-block:nth-child(2){
          background: url('/assets/img/vision/image2-min.png')  no-repeat center;
          background-size: cover;
        }
      }
      .vision-collage:nth-child(2){
        .img-block:nth-child(1){
          background: url('/assets/img/vision/image3-min.png') no-repeat center;
          background-size: cover;
        }
        .img-block:nth-child(2){
          background: url('/assets/img/vision/image4-min.png') no-repeat center;
          background-size: cover;
        }
      }
    }
  }
}
