@import "../../index";

.wrapper .franchising-block{
  @extend .stretch;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: url("/assets/img/franchising/bg.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  .container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    height: fit-content;
    .page-btn{
      margin-top: 1.54rem;
      margin-left: auto;
    }
    .f-title{
      @extend .header-bold;
      color: #262626;
      font-family: "Gilroy", sans-serif;
      text-transform: uppercase;
      margin-bottom: 1.65rem;
    }
    .f-text{
      @extend .header-medium;
      color: #262626;
      font-family: 'Gilroy', sans-serif;
      position: relative;
      padding-left: 1.6rem;
      max-width: 1205px;
      margin-bottom: 2.4rem;
    }
    .f-text:before{
      content: '';
      border: 1px solid  #0a2360;
      background: #0a2360;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
    .elements{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 1.14rem;
      .element{
        padding: 0.85rem;
        background: url("/assets/img/franchising/bg-box.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .box{
          background-color: rgba(255, 255, 255, 0.75);
          min-height: 23.48rem;
          padding: 1.82rem 2.11rem;
          img{
            height: 2.28rem;
            margin-bottom: 1.2rem;
          }
          .b-title{
            @extend .header-middle;
            font-family: 'Gilroy', sans-serif;
            color: #262626;
            margin-bottom: 0.6rem;
          }
          .b-text{
            @extend .text-medium-small;
            color: #262626;
            font-family: 'Gilroy', sans-serif;
            line-height: 1.37rem;
            padding-bottom: 0.7rem;

            >div{
              @extend .text-medium-small;
              margin-top: 0.35rem;
              color: #262626;
              font-family: 'Gilroy', sans-serif;
              line-height: 1.37rem;
            }
          }
          .list-t{
            @extend .text-medium-small;
            color: #262626;
            font-family: 'Gilroy', sans-serif;
            margin-bottom: 0.04rem;
          }
          ul{
            padding-left: 0.87rem;
            li{
              @extend .text-medium-small;
              color: #262626;
              font-family: 'Gilroy', sans-serif;
              line-height: 1.6rem;
            }
          }
        }
      }
      .element:nth-child(2){
        background: url("/assets/img/franchising/bg-box2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .element:nth-child(3){
        background: url("/assets/img/franchising/bg-box3.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
.franchising-box{
  .fp-scrollable{
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .wrapper {
   .fp-tableCell .franchising-block{
      .container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-height: 100%;
        //height: 90%;
        //overflow-y: auto;
        .elements{
          grid-row-gap: 1.14rem;
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
