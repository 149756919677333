@import '~react-responsive-select/dist/ReactResponsiveSelect.css';
@import "../../index";
.rrs{
  height: 2.857rem;
  //max-height: 2.857rem;
  .rrs__label__text{
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
    color: rgba(38, 38, 38, 0.8);
    max-width: 100%;
    overflow: hidden;
  }
  >div > div{
    background: rgba(255, 255, 255, 0.8);
  }
  div{
    height: 100%;
  }
  .rrs__label{
    display: flex;
    align-items: center;
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
    color: rgba(38, 38, 38, 0.8);
  }
  li{
    @extend .text-medium-small;
    font-family: 'Gilroy', sans-serif;
    color: rgba(38, 38, 38, 0.8);
  }
    li div{
      @extend .text-medium-small;
      font-family: 'Gilroy', sans-serif;
      color: rgba(38, 38, 38, 1);
      display: flex;
      span{
        @extend .text-medium-small;
        font-family: 'Gilroy', sans-serif;
        color: grey;
        padding-right: .5em;
        padding-left: 0;
        width: 4rem;
      }
    }
}
.rrs--options-visible .rrs__options{
  max-height: 40vh;
}
.rrs__option.rrs__option--selected, .rrs__option:hover, .rrs__option.rrs__option--next-selection{
  color: rgba(38, 38, 38, 1);
}

.rrs__button + .rrs__options{
  top: 3.3rem;
  background: rgba(255, 255, 255, 1);
  border-top: 2px solid white;
}

.rrs__button{
  border-radius: 0;
}
.rrs--options-visible .rrs__button:after{
  content: '';
  position: absolute;
  top: 2.32rem;
  right: calc(1.2rem - 0.4rem + 2px);
  padding: 0.3rem 0;
  color: white;
  border-top: 0;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid;
  border-left: .4rem solid transparent;
}
.rrs__option.rrs__option--next-selection{
  background: #eee;
}
.rrs__option{
  padding: .2rem 1rem;
  display: flex;
  align-items: center;
  div{
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}
