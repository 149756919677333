@import "../../../../index";

.terms-of-use-info-popup{
  transition: 0.3s;
  opacity: 0;
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  .popup-lay{
    @extend  .container;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42rem;
    position: relative;
    .content{
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding: 1.5rem 3.5rem 3rem;
      height: 100%;
      width: 100%;

      .content-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        height: 100%;
        overflow-y: auto;
      }
    }
    .head-text{
      font-family: "Gilroy", sans-serif;
      color: #262626;
      font-size: 1.257rem;
      font-weight: 700;
      margin: 0;
    }

    .subhead-text{
      @extend .text-semibold-small;
      font-family: "Gilroy", sans-serif;
      color: #262626;
      margin: 0.4rem 0 1.2rem;
    }

    .body-text {
      @extend .text-regular-small;
      margin-bottom: 0.8rem;
      color: #808080;

      br {
        line-height: 0;
      }

      strong {
        @extend .text-semibold-small;
        color: #262626;
      }

      i {
        @extend .text-regular-small;
        color: #808080;
      }
    }


    .back-line {
      @extend .text-medium-small;
      font-family: 'Gilroy', sans-serif;
      color: #808080;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 1rem;

      img {
        margin-right: .57rem;
        height: fit-content;
      }
    }
    .close{
      position: absolute;
      top: 0;
      right: -2.85rem;
      cursor: pointer;
      img{
        height: 1.42rem;
        width: auto;
      }
    }
  }
}
.terms-of-use-info-popup-open{
  display: flex;
  opacity: 1;
}
